<template>
  <section class="section is-fullheight">
    <div class="container is-fullheight">
      <div class="columns is-centered is-vcentered is-fullheight">
        <div class="column is-narrow">
          <button
            class="button is-large is-white is-loading"
            v-if="$wait.is('loading')"
          />
          <div
            class="content"
            v-if="!$wait.is('loading')"
          >
            <div class="field">
              <VrmTag
                v-if="vrm"
                size="large"
                v-bind="{ vrm }"
              />
            </div>
            <div
              class="field"
              v-if="err"
            >
              <span class="icon is-valigned has-text-danger">
                <i class="fa fa-exclamation-triangle" />
              </span>
              <span class="has-text-grey-light">
                {{ errorMessage }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as companion from 'modules/companion/services'
import * as autocheck from 'modules/autocheck/services'
import * as provenance from 'modules/provenance/services'

export default {
  name: 'StaticSearch',
  components: { 
    VrmTag: () => import('core/components/VrmTag') 
  },
  data: () => ({ 
    err: null,
    errorDetails: null
  }),
  computed: {
    service() {
      return this.$route.params.service || 'companion'
    },
    vrm() {
      return this.$route.query.vrm
    },
    mileage() {
      return this.$route.query.mileage
    },
    token() {
      return this.$route.query.token
    },
    userId() {
      return this.$route.query.user_id
    },
    dealerId() {
      return this.$route.query.dealer_id
    },
    errorMessage() {
      if (this.errorDetails) {
        return this.errorDetails
      }

      const defaultMessage = 'We are currently unable to check this vehicle'
      const messages = {
        companion: 'Unable to retrieve vehicle valuation at this time',
        autocheck: 'We are currently unable to complete an AutoCheck',
        motorcheck: 'We are currently unable to complete a MotorCheck',
        default: defaultMessage
      }
      return messages[this.service] || messages.default
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    getTrackingData() {
      const baseData = {
        service: this.service,
        vrm: this.vrm,
        mileage: this.mileage,
      }
      
      const additionalData = {
        companion: { 
          date: this.date,
          dealer_id: this.dealerId,
          user_id: this.userId
        },
        autocheck: {},
        motorcheck: {}
      }

      return { ...baseData, ...additionalData[this.service] }
    },
    async search() {
      this.$wait.start('loading')
      this.err = null
      this.errorDetails = null
      
      const searchMethods = {
        companion: this.searchCompanion,
        autocheck: this.searchAutoCheck,
        motorcheck: this.searchMotorCheck
      }

      try {
        if (!this.vrm) {
          throw new Error('VRM is required')
        }

        await searchMethods[this.service]()
        this.$mxp.track('static_search', this.getTrackingData())
      } catch (err) {
        this.err = err
        
        if (err.response?.status === 404) {
          this.errorDetails = 'Vehicle not found'
        } else if (err.response?.status === 401) {
          this.errorDetails = 'Authentication failed. Please try again'
        } else if (err.message === 'VRM is required') {
          this.errorDetails = 'Please provide a valid vehicle registration'
        } else if (err.response?.data?.message) {
          this.errorDetails = err.response.data.message
        }
      } finally {
        this.$wait.end('loading')
      }
    },
    async searchCompanion() {
      try {
        let mileage = this.mileage ? parseInt(this.mileage, 10) : undefined

        if (isNaN(mileage)) {
          mileage = undefined
        }

        await companion.value({ 
          vrm: this.vrm,
          isStatic: true,
          mileage,
          date: this.date
        })
      } catch (err) {
        throw err
      }
    },
    async searchAutoCheck() {
      const { uuid } = await autocheck.createStaticAutoCheck({
        vrm: this.vrm,
        mileage: this.mileage,
        token: this.token
      })
      await this.$router.push(`/autocheck/${uuid}`)
    },
    async searchMotorCheck() {
      const data = await provenance.precheck({ 
        value: this.vrm, 
        type: 'vrm',
        mileage: this.mileage 
      })

      this.$modal.open('provenance/precheck', {
        serviceType: 'Motorcheck',
        vrm: this.vrm,
        vin: data.vin,
        description: provenance.buildPreDescription(data),
        mileage: data.mileage,
        submitButtonText: 'Generate Report',
        instructionsText: 'Choose the MotorCheck report options you would like to include.',
        customTitle: `MotorCheck for ${this.vrm}`
      })
    }
  }
}
</script>
